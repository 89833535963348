import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import Table from "../general/table";
import ActionBar from "../general/actionBar";
// import Input from '../general/input';
// import Dropdown from "../general/dropdown";
import TitleBanner from "../general/titleBanner";

import SearchIcon from "../../assets/icons8-search.svg";
import Owner1Illustration from "../../assets/owner1-illustration.svg";
import Owner2Illustration from "../../assets/owner2-illustration.svg";

const Owners = (props) => {
	const [owners, setOwners] = useState();
	const [entriesNo, setEntriesNo] = useState(20);
	const [filterStr, setFilterStr] = useState("");

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "get/users", {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setOwners(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const selectRow = (e) => {
		console.log(e.target);
		e.target.closest("tr").classList.toggle("is-selected");
	};

	return (
		<div>
			<ActionBar
				leftSide={
					<div className="field has-addons">
						<div className="control">
							<button className="button is-static">
								<img
									src={SearchIcon}
									height="16"
									className="is-icon is-16"
									alt="Search..."
								></img>
							</button>
						</div>
						<div className="control is-expanded">
							<input
								className="input"
								type="text"
								placeholder="Owner name or email"
								onChange={(e) => setFilterStr(e.target.value)}
							/>
						</div>
					</div>
				}
				// rightSide={
				//   <button className="button is-small is-white">
				//     <span>Filter</span>
				//     <span className="icon is-small">
				//       <svg className="is-16" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50"><path d="M 3.8125 2 C 3.335938 2.089844 2.992188 2.511719 3 3 L 3 6 C 3.003906 6.257813 3.101563 6.503906 3.28125 6.6875 L 19 23.375 L 19 41 C 19.007813 41.347656 19.199219 41.667969 19.5 41.84375 L 29.5 47.84375 C 29.804688 48.019531 30.183594 48.023438 30.492188 47.847656 C 30.796875 47.675781 30.992188 47.351563 31 47 L 31 23.375 L 46.5625 6.84375 C 46.574219 6.832031 46.582031 6.824219 46.59375 6.8125 L 46.71875 6.6875 C 46.765625 6.640625 46.808594 6.585938 46.84375 6.53125 C 46.867188 6.511719 46.886719 6.492188 46.90625 6.46875 C 46.964844 6.339844 46.996094 6.203125 47 6.0625 C 47 6.042969 47 6.019531 47 6 C 47.003906 5.949219 47.003906 5.894531 47 5.84375 L 47 3 C 47 2.449219 46.550781 2 46 2 L 4 2 C 3.96875 2 3.9375 2 3.90625 2 C 3.875 2 3.84375 2 3.8125 2 Z M 5 4 L 45 4 L 45 5.625 L 29.5625 22 L 20.4375 22 L 5 5.625 Z M 21 24 L 29 24 L 29 45.25 L 21 40.46875 Z"></path></svg>
				//     </span>
				//   </button>
				// }
			/>
			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<TitleBanner
							leftImg={Owner1Illustration}
							rightImg={Owner2Illustration}
							leftTop={"12px"}
							rightTop={"40px"}
							title="Owners"
							subtitle={(owners ? owners.length : "0") + " owners found"}
						/>
					</div>
				</div>
			</section>

			<section className="section">
				{/* <div className="level">
					<div className="level-left">
						<Dropdown name="Actions">
							<button className="dropdown-item">Delete</button>
							<button className="dropdown-item">Send</button>
						</Dropdown>
					</div>
					<div className="level-right">
						<Link to="/admin/owner/new" className="button is-primary">
							+ Add owner
						</Link>
					</div>
				</div> */}

				<div className="columns">
					<div className="column is-12">
						<Table
              hasCheckbox={true}
							thTitles={[
								"Name",
								"Email",
								"Address",
								"City",
								"Mobile",
								"No. of pets",
								"Pets",
							]}
						>
							{owners ? (
								owners
									.filter((owner) => {
										if (
											JSON.stringify(owner)
												.toLowerCase()
												.includes(filterStr.toLowerCase())
										) {
											return owner;
										}
									})
									.slice(0, entriesNo)
									.map((owner, index) => {
										return (
											<tr key={index}>
												<td nowrap="true">
													<input
														type="checkbox"
														onChange={(e) => selectRow(e)}
													></input>
												</td>
												<td nowrap="true">
													<Link to={"/admin/owner/" + owner.id}>
														{owner.first_name} {owner.last_name}
													</Link>
												</td>
												<td nowrap="true">{owner.email}</td>
												<td nowrap="true">{owner.address1}</td>
												<td nowrap="true">{owner.city}</td>
												<td nowrap="true">{owner.mobile}</td>
												<td nowrap="true">
													{owner.pets ? owner.pets.length : ""}
												</td>
												<td nowrap="true">
													{owner.pets
														? owner.pets.map((pet, index2) => {
																return (
																	<span key={index2}>
																		<Link to={"/admin/pet/" + pet.id}>
																			{pet.name}
																			{owner.pets.length > 1 ? ", " : ""}
																		</Link>
																	</span>
																);
														  })
														: ""}
												</td>
												{/* <td nowrap="true">{owner.dob}</td>
                    <td nowrap="true">{owner.tag_number}</td>
                    <td nowrap="true">{owner.gender}</td>
                    <td nowrap="true">{owner.breed}</td> */}
											</tr>
										);
									})
							) : (
								<tr>
									<td nowrap="true"></td>
									<td nowrap="true">
                    <Skeleton height={24} width={"100%"} />
									</td>
                  <td nowrap="true">
                    <Skeleton height={24} width={"100%"} />
									</td>
                  <td nowrap="true">
                    <Skeleton height={24} width={"100%"} />
									</td>
                  <td nowrap="true">
                    <Skeleton height={24} width={"100%"} />
									</td>
								</tr>
							)}
						</Table>
						<button
							className="button is-primary"
							onClick={() => setEntriesNo(entriesNo + 20)}
						>
							Load more...
						</button>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Owners;

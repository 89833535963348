import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ProfilePrivacy = (props) => {
	const [loading, setLoading] = useState(false);
	const [settings, setSettings] = useState();

	useEffect(() => {
		getSettings();
		return () => {
			setSettings("");
		};
	}, []);

	const getSettings = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "privacy/" + props.user.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setSettings(res.data.data[0]);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateSettings = () => {
		setLoading(true);

		let payload;

		if (settings !== undefined) {
			payload = settings;
		} else {
			payload = {
        email: 1,
				first_name: 1,
				last_name: 0,
			};
			setTimeout(() => getSettings(), 100);
		}

		axios
			.put(
				process.env.REACT_APP_API_URL + "privacy/" + props.user.id,
				payload,
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				toast.success("Privacy settings updated!");
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};


	const handleChange = (key, value) => {
		console.log(key, value);
		setSettings({
			...settings,
			[key]: value === false ? 0 : 1,
		});
		setTimeout(() => console.log(settings), 500);
	};

	return (
		<div className="columns is-multiline">
			<div className="column is-3 content">
				<p>
					Select the info that you want to display when your pet's tag is
					scanned.
				</p>
				<p>
					This will be public information that in case your pet gets lost, will
					be very important.
				</p>
			</div>
			<div className="column is-9">
				{/* {settings && settings ? ( */}
				<div className="columns is-multiline">
					<div className="column is-12">
						<p>User</p>
					</div>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Email"
						id="email"
						checked={settings && settings.email ? settings.email : false}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="First Name"
						id="first_name"
						checked={
							settings && settings.first_name ? settings.first_name : false
						}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Last Name"
						id="last_name"
						checked={
							settings && settings.last_name ? settings.last_name : false
						}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Phone"
						id="phone"
						checked={settings && settings.phone ? settings.phone : false}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Mobile"
						id="mobile"
						checked={settings && settings.mobile ? settings.mobile : false}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Address"
						id="show_address"
						checked={
							settings && settings.show_address ? settings.show_address : false
						}
					/>
					<div className="column is-12">
						<p>Pet</p>
					</div>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Breed"
						id="breed"
						checked={settings && settings.breed ? settings.breed : false}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Color"
						id="color"
						checked={settings && settings.color ? settings.color : false}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="DOB"
						id="dob"
						checked={settings && settings.dob ? settings.dob : false}
					/>
					<CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Pet Image"
						id="image"
						checked={settings && settings.image ? settings.image : false}
					/>
					{/* <CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Medical Records"
						id="medical_records"
						checked={
							settings && settings.medical_records
								? settings.medical_records
								: false
						}
					/> */}
          <CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Pet Name"
						id="pet_name"
						checked={
							settings && settings.pet_name
								? settings.pet_name
								: false
						}
					/>
          {/* <CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Species"
						id="species"
						checked={
							settings && settings.species
								? settings.species
								: false
						}
					/> */}
					{/* <CustomCheckBox
						handleChange={(key, value) => handleChange(key, value)}
						label="Microchip ID"
						id="microchip_id"
						checked={
							settings && settings.microchip_id ? settings.microchip_id : false
						}
					/> */}
					<div className="column is-12">
						<button
							className={
								loading ? "button is-success is-loading" : "button is-success"
							}
							onClick={() => updateSettings()}
						>
							Update privacy information
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePrivacy;

const CustomCheckBox = (props) => {
	return (
		<div className="column is-4">
			<label className="checkbox">
				<input
					type="checkbox"
					id={props.id}
					checked={props.checked}
					onChange={(e) => props.handleChange(e.target.id, e.target.checked)}
				/>
				{props.label}
			</label>
		</div>
	);
};

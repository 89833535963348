import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const PetDelete = (props) => {
	const [deleteModal, setDeleteModal] = useState(false);

	const history = useHistory();

	const deletePet = () => {
		axios
			.delete(process.env.REACT_APP_API_URL + "pets/" + props.match.params.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(function (response) {
				console.log(response);
				toast.success("Pet deleted!");
				history.push("/user/pets");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<div className="content">
							<div className="mb-4">
								<span className="tag is-danger is-light is-large has-text-weight-bold">
									Danger zone
								</span>
							</div>
							<div className="columns">
								<div className="column is-4">
									<p>If you proceed with this action, you cannot reverse it.</p>
									<button
										className="button is-danger"
										onClick={() => setDeleteModal(true)}
									>
										Delete pet
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{deleteModal ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => setDeleteModal(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head has-background-danger-light ">
							<p className="modal-card-title has-text-danger-dark">
								Delete pet
							</p>
							<button
								className="delete"
								onClick={() => setDeleteModal(false)}
							></button>
						</header>
						<section className="modal-card-body">
							<p>
								Deleting your pet cannot be reversed and you will lose all the
								pet information. Are you sure you want to proceed?
							</p>
						</section>
						<footer className="modal-card-foot">
							<button className="button is-danger" onClick={() => deletePet()}>
								Yes, delete my pet
							</button>
							<button
								className="button is"
								onClick={() => setDeleteModal(false)}
							>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default PetDelete;

PetDelete.propTypes = {
	pet: PropTypes.object,
};

import React from 'react';
import PropTypes from 'prop-types';

const PetMissingBanner = (props) => {
  const pet = props.pet;
  
	return (
		<section className="section">
			<div className="columns">
				<div className="column is-12">
					<div className="card has-background-danger">
						<div className="card-content content">
							<p className="has-text-weight-bold has-text-white">
								{pet ? pet.name : ""} is missing
							</p>
							<p className="has-text-white">
								To help you find him more easily, we generated a Missing Poster
								that can be shared online or printed.{" "}
							</p>
							<button className="button is-white is-small">
								Download Poster
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PetMissingBanner;

PetMissingBanner.propTypes = {
  pet: PropTypes.object
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Table from "../../general/table";
import Input from "../../general/input";

const PetLicensesList = (props) => {
	const [activateTagModal, setActivateTagModal] = useState(false);

	const pet = props.pet;

	const activateTag = () => {
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"tags/activate/" +
					document.getElementById("tagNumber").value +
					"/" +
					pet.id,
				{
					tagNumber: document.getElementById("tagNumber").value,
					petId: pet.id,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(function (response) {
				if (!response.data.success) {
					toast.error(response.data.message);
				} else {
					setActivateTagModal(false);
					toast.success("Tag activated!");
					props.getPet();
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const selectRow = (e) => {
		e.target.closest("tr").classList.toggle("is-selected");
	};

	return (
		<>
			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{pet ? pet.name : ""}'s licenses
						</p>
					</div>
					<div className="level-right">
						<button
							className="button is-dark is-small"
							onClick={() => setActivateTagModal(true)}
						>
							Activate tag
						</button>
					</div>
				</div>
				<Table
					thTitles={["License #", "License Date", "Expiry Date", "Status"]}
				>
					<tr>
						<td nowrap="true">
							<p>{pet && pet.tag_number ? pet.tag_number : "No license"}</p>
						</td>

						<td nowrap="true">
							<p>
								{pet && pet.license.length > 0
									? moment(pet.license[0].license_date).format("MMM Do YYYY")
									: "N/A"}
							</p>
						</td>

						<td nowrap="true">
							<p>
								{pet && pet.license.length > 0
									? moment(pet.license[0].license_expiration_date).format(
											"MMM Do YYYY"
									  )
									: "N/A"}
							</p>
						</td>
						<td nowrap="true">
							{pet &&
							pet.license &&
							pet.license.length > 0 &&
							moment(
								pet.license[pet.license.length - 1].license_expiration_date
							).isAfter(moment()) ? (
								<p className="has-text-success">Active</p>
							) : (
								<p className="has-text-danger">Inactive</p>
							)}
						</td>
					</tr>
				</Table>
			</section>

			{activateTagModal ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => setActivateTagModal(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Activate tag</p>
							<button
								className="delete"
								onClick={() => setActivateTagModal(false)}
							></button>
						</header>
						<section className="modal-card-body">
							<Input
								id="tagNumber"
								label="Tag number"
								placeholder="10-digit number"
							/>
						</section>
						<footer className="modal-card-foot">
							<button className="button is-dark" onClick={() => activateTag()}>
								Activate tag
							</button>
							<button
								className="button"
								onClick={() => setActivateTagModal(false)}
							>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default PetLicensesList;

PetLicensesList.propTypes = {
	pet: PropTypes.object,
	getPet: PropTypes.func,
};

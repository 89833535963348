import React, { useState } from "react";
import axios from "axios";
import "./license-form.scss";

// Form steps
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";

import Puppies from "../../assets/puppies.jpg";

const License = (props) => {
	const [step, setStep] = useState(1);
	const [error, setError] = useState();

	// Step 1
	const [fname, setFname] = useState();
	const [lname, setLname] = useState();
	const [email, setEmail] = useState();
	const [county, setCounty] = useState("");
	const [address, setAddress] = useState();
	const [city, setCity] = useState();
	const [state, setState] = useState();
	const [zip, setZip] = useState();
	const [apt, setApt] = useState();

	// Step 2
	const [male, setMale] = useState(false);
	const [spayed, setSpayed] = useState(true);

	// Step 3
	const [rabiesCert, setRabiesCert] = useState("");
	const [tagDuration, setTagDuration] = useState("1");
	const [rabiesDuration, setRabiesDuration] = useState("1");
	const [price, setPrice] = useState(
		props.domain && props.domain.tag_price_spayed_neutered
			? props.domain.tag_price_spayed_neutered
			: ""
	);

	const submitForm = () => {
		// Start loading animation for button
		document.querySelector(".submit-button").classList.add("is-loading");
    setError("")

		let certDate = document.getElementById("certDate");
		let autoRenew = document.getElementById("auto-renew");
		let petName = document.getElementById("petName");
		let petDob = document.getElementById("petDob");
		let petWeight = document.getElementById("petWeight");
		let petColor = document.getElementById("petColor");
		let petBreed = document.getElementById("petBreed");
		let petHair = document.getElementById("petHair");
		var tagNo = "L" + Math.floor(100000 + Math.random() * 900000);

		let licenseData = {
			first_name: fname,
			last_name: lname,
			email: email,
			address1: address ? address.formatted_address : "",
			address2: "",
			apt: apt,
			city: city,
			state: state,
			county: props.domain.county_site_name
				? props.domain.county_site_name
				: county,
			zipcode: zip,
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
			certificate: rabiesCert ? rabiesCert : "",
			cert_date: certDate.value,
      expiration_date: rabiesDuration, // Rabies certification duration
			cert_time: tagDuration, // This is tag duration
			auto_renew: autoRenew.checked ? true : false,
			cert_verified: false,
			tag_number: "",
			tag_activated: false,
			pet_type: "Dog",
			pet_status: spayed ? "Spayed/Neutered" : "Unaltered",
      spayed_neutered: spayed ? spayed : false,
			pet_gender: male ? "Male" : "Female",
			pet_name: petName.value,
			pet_dob: petDob.value,
			pet_weight: petWeight.value,
			pet_breed: petBreed.value,
			pet_color: petColor.value,
			pet_hair: petHair.value,
      payment_option: "card"
		};

		console.log(licenseData);

		axios
			.post(process.env.REACT_APP_API_URL + "register", licenseData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}  `,
				},
			})
			.then(function (response) {
        console.log(response)
        if (!response.data.success) { 
          setError(response.data.message[0])
          document.querySelector(".submit-button").classList.remove("is-loading");
        } else {
          document.querySelector(".submit-button").classList.remove("is-loading");
          if (step < 6) {
            setStep(step + 1);
          }
        }
			})
			.catch(function (error) {
				document.querySelector(".submit-button").classList.remove("is-loading");
				setError(
					"There was an error sending the information, please make sure everything is filled in correctly."
				);
				console.log(error);
			});
	};

	const nextStep = (e) => {
		e.preventDefault();
		if (step < 6) {
			setStep(step + 1);
		}
		console.log(step);
	};
	const prevStep = (e) => {
		e.preventDefault();
		if (step > 1) {
			setStep(step - 1);
		}
		console.log(step);
	};

	return (
		<div>
			<div
				className="broward-header"
				style={{ backgroundImage: "url(" + Puppies + ")" }}
			>
				<div className="container">
					<div className="section has-text-centered">
						<div className="columns is-centered">
							<div className="column is-7">
								<p className="has-text-white is-size-5 has-text-weight-bold">
									Thank you for protecting the safety of your pet by purchasing
									a {props.county} County Pet License.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="section">
				<div className="columns is-centered">
					<div className="column is-10">
						<div className="columns is-mobile progress-tabs is-aligned-bottom is-multiline">
							<div
								className={
									step + 1 > 1
										? "column has-text-centered active progress-tab"
										: "column has-text-centered progress-tab"
								}
								id="1"
							>
								<p className="is-uppercase is-size-7">Order details</p>
								<span></span>
							</div>
							<div
								className={
									step + 1 > 2
										? "column has-text-centered active progress-tab"
										: "column has-text-centered progress-tab"
								}
								id="2"
							>
								<p className="is-uppercase is-size-7">Pet details</p>
								<span></span>
							</div>
							<div
								className={
									step + 1 > 3
										? "column has-text-centered active progress-tab"
										: "column has-text-centered progress-tab"
								}
								id="3"
							>
								<p className="is-uppercase is-size-7">Rabies details</p>
								<span></span>
							</div>
							<div
								className={
									step + 1 > 4
										? "column has-text-centered active progress-tab"
										: "column has-text-centered progress-tab"
								}
								id="4"
							>
								<p className="is-uppercase is-size-7">Terms & Conditions</p>
								<span></span>
							</div>
							<div
								className={
									step + 1 > 5
										? "column has-text-centered active progress-tab"
										: "column has-text-centered progress-tab"
								}
								id="5"
							>
								<p className="is-uppercase is-size-7">Checkout</p>
								<span></span>
							</div>
						</div>
					</div>
				</div>

				<Step1
					step={step}
					domain={props.domain}
					fname={fname}
					lname={lname}
					email={email}
					address={address}
					county={county}
					city={city}
					state={state}
					zip={zip}
					apt={apt}
					setAddress={setAddress}
					setCounty={setCounty}
					setCity={setCity}
					setState={setState}
					setZip={setZip}
					setApt={setApt}
					setFname={setFname}
					setLname={setLname}
					setEmail={setEmail}
				/>

				<Step2
					step={step}
					domain={props.domain}
					male={male}
					spayed={spayed}
					price={price}
					setPrice={setPrice}
					setMale={setMale}
					setSpayed={setSpayed}
				/>

				<Step3
					step={step}
					domain={props.domain}
					price={price}
					tagDuration={tagDuration}
					rabiesDuration={rabiesDuration}
					rabiesCert={rabiesCert}
					setPrice={setPrice}
					setRabiesCert={setRabiesCert}
					setTagDuration={setTagDuration}
					setRabiesDuration={setRabiesDuration}
				/>

				<Step4 step={step} />

				<Step5 step={step} {...props} />

				<Step6 step={step} 
          domain={props.domain} 
        />

				<div className="columns is-centered">
					<div className="column is-10">
						<div className="columns is-multiline">
							<div className="column is-6">
								<div>
									{error ? <span className="tag is-danger">{error}</span> : ""}
								</div>
							</div>
							<div className="column is-6"></div>
							<div className="column is-6">
								<div className="buttons">
									{step > 1 && step < 6 ? (
										<button
											onClick={(e) => prevStep(e)}
											className="button is-white"
										>
											Previous
										</button>
									) : (
										""
									)}

									{step < 5 &&
									props.domain &&
									props.domain.county_site_name.match(
										county.replace(/\s/g, "").toLowerCase()
									) ? (
										<button
											onClick={(e) => nextStep(e)}
											className="button is-primary next-button"
										>
											Next
										</button>
									) : step === 5 ? (
										<button
											className="button is-primary submit-button"
											onClick={() => submitForm()}
										>
											Submit
										</button>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default License;

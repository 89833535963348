import React, { useState } from "react";
import Input from "../general/input";
import axios from "axios";
import { toast } from "react-toastify";

const ProfileData = (props) => {
	const [loading, setLoading] = useState(false);
  // const [profileImage, setProfileImage] = useState();
	const user = props.user;

	const save = () => {
		setLoading(true);

		let data = {
			id: props.user.id,
			first_name: document.getElementById("first_name").value
				? document.getElementById("first_name").value
				: "",
			last_name: document.getElementById("last_name").value
				? document.getElementById("last_name").value
				: "",
			mobile: document.getElementById("mobile").value
				? document.getElementById("mobile").value
				: "",
			phone: document.getElementById("phone").value
				? document.getElementById("phone").value
				: "",
			address1: document.getElementById("address1").value
				? document.getElementById("address1").value
				: "",
			address2: document.getElementById("address2").value
				? document.getElementById("address2").value
				: "",
			apt: document.getElementById("apt").value
				? document.getElementById("apt").value
				: "",
			city: document.getElementById("city").value
				? document.getElementById("city").value
				: "",
			zipcode: document.getElementById("zip").value
				? document.getElementById("zip").value
				: "",
			state: document.getElementById("state").value
				? document.getElementById("state").value
				: "",
			county: document.getElementById("county").value
				? document.getElementById("county").value
				: "",
			temp_address1: "",
			temp_address2: "",
			temp_address_apt: "",
			temp_address_zipcode: "",
			temp_address_state: "",
			temp_address_country_code: "",
			temp_address_first_name: "",
			temp_address_last_name: "",
			temp_address_phone: "",
			temp_address_cell: "",
			picture: props.profileImage,
		};

    console.log(data)

		axios
			.post(
				process.env.REACT_APP_API_URL + "user_update/" + props.user.id,
				data,
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				toast.success("Profile updated!");
        console.log(res)
				setLoading(false);
			})
			.catch((err) => {
				toast.error(err.data.message);
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<div className="columns is-multiline">
			<div className="column is-4">
				<Input id="first_name" label="First Name" value={user.first_name} />
			</div>
			<div className="column is-4">
				<Input id="last_name" label="Last Name" value={user.last_name} />
			</div>
			<div className="column is-4">
				<Input id="email" label="Email" value={user.email} disabled={true} />
			</div>

			<div className="column is-12">
				<p className="has-text-weight-bold">Address</p>
				<p className="is-size-7">
					This is essential information for someone who may find your lost pet
				</p>
			</div>
			<div className="column is-6">
				<Input id="address1" label="Address 1" value={user.address1} />
			</div>
			<div className="column is-6">
				<Input id="address2" label="Address 2" value={user.address2} />
			</div>
			<div className="column is-2">
				<Input id="apt" label="Apt." value={user.apt} />
			</div>
			<div className="column is-2">
				<Input id="city" label="City" value={user.city} />
			</div>
			<div className="column is-2">
				<Input id="state" label="State" value={user.state} disabled={true} />
			</div>
			<div className="column is-2">
				<Input id="county" label="County" value={user.county} disabled={true} />
			</div>
			<div className="column is-2">
				<Input id="zip" label="ZIP code" value={user.zipcode} />
			</div>

			<div className="column is-12">
				<p className="has-text-weight-bold">Phone</p>
				<p className="is-size-7">
					This is essential information for someone who may find your lost pet
				</p>
			</div>
			<div className="column is-4">
				<Input id="phone" label="Phone" value={user.phone} />
			</div>
			<div className="column is-4">
				<Input id="mobile" label="Mobile" value={user.mobile} />
			</div>
			<div className="column is-12">
				<button className={loading ? "button is-success is-loading" : "button is-success"} onClick={() => save()}>
					Update personal information
				</button>
			</div>
		</div>
	);
};

export default ProfileData;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import ActionBar from "../general/actionBar";
import Table from "../general/table";
import Skeleton from "react-loading-skeleton";

import ProfileData from "../profile/profileData";
import ProfileImage from "../profile/profileImage";
import OwnerNotes from "./ownerNotes";
import OwnerExchanges from "./ownerExchanges";

const OwnerSingle = (props) => {
	const [owner, setOwner] = useState();
	const [profileImage, setProfileImage] = useState();
	const [privileges, setPrivileges] = useState();
	const [loading, setLoading] = useState(false);
	const [convertedContent, setConvertedContent] = useState(null);

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "user/" + props.match.params.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setOwner(res.data.data);
				setPrivileges(res.data.data.privileges);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const save = () => {
		setLoading(true);

		let data = {
			id: owner.id,
			first_name: document.getElementById("first_name").value
				? document.getElementById("first_name").value
				: "",
			last_name: document.getElementById("last_name").value
				? document.getElementById("last_name").value
				: "",
			mobile: document.getElementById("mobile").value
				? document.getElementById("mobile").value
				: "",
			phone: document.getElementById("phone").value
				? document.getElementById("phone").value
				: "",
			address1: document.getElementById("address1").value
				? document.getElementById("address1").value
				: "",
			address2: document.getElementById("address2").value
				? document.getElementById("address2").value
				: "",
			apt: document.getElementById("apt").value
				? document.getElementById("apt").value
				: "",
			city: document.getElementById("city").value
				? document.getElementById("city").value
				: "",
			zipcode: document.getElementById("zip").value
				? document.getElementById("zip").value
				: "",
			state: document.getElementById("state").value
				? document.getElementById("state").value
				: "",
			county: document.getElementById("county").value
				? document.getElementById("county").value
				: "",
			temp_address1: "",
			temp_address2: "",
			temp_address_apt: "",
			temp_address_zipcode: "",
			temp_address_state: "",
			temp_address_country_code: "",
			temp_address_first_name: "",
			temp_address_last_name: "",
			temp_address_phone: "",
			temp_address_cell: "",
			picture: profileImage,
			// privileges: parseInt(privileges),
			notes: convertedContent,
		};

		console.log(data);

		axios
			.post(process.env.REACT_APP_API_URL + "user_update/" + owner.id, data, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					toast.success("Owner updated!");
				} else {
					toast.error(res.data.message);
				}
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toast.error(err.message);
			});
	};

	return (
		<div>
			<ActionBar
				{...props}
				buttonLeft={<button className="button is-text is-small">Cancel</button>}
				rightSide={
					<div className="buttons">
						<button
							className={
								loading
									? "button is-success is-small is-loading"
									: "button is-success is-small"
							}
							onClick={() => save()}
						>
							Save
						</button>
					</div>
				}
				back={true}
			/>
			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<p className="has-text-weight-bold is-size-4">
							{owner ? owner.first_name : "Name"}'s information
						</p>
					</div>
				</div>

				<div className="columns">
					<div className="column is-3">
						{owner ? (
							<ProfileImage
								profileImage={profileImage ? profileImage : owner.picture}
								setProfileImage={setProfileImage}
							/>
						) : (
							<Skeleton height={120} width={"100%"} />
						)}
					</div>
					<div className="column is-9">
						<ProfileData user={owner ? owner : ""} />
						{/* <div className="field">
							<label className="label">Permissions</label>
							<div className="control">
								<div className="select is-primary">
									<select
										id="user-privileges"
										value={privileges ? privileges : ""}
										onChange={(e) => {
											setPrivileges(e.target.value);
											console.log(parseInt(e.target.value));
										}}
									>
										<option value="0">User</option>
										<option value="4">County Manager</option>
										<option value="5">County Worker</option>
									</select>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>

			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{owner ? owner.first_name : ""}'s pets
						</p>
					</div>
				</div>
				<Table
					thTitles={[
						"Name",
						"Type",
						"Breed",
						"Color",
						"Tag #",
						"Date of birth",
						"Email",
					]}
				>
					{owner && owner.pets.length > 0 ? (
						owner.pets.map((pet, index) => {
							return (
								<tr key={index}>
									<td nowrap="true">
										<Link to={"/admin/pet/" + pet.id}>{pet.name}</Link>
									</td>
									<td nowrap="true">{pet.type}</td>
									{/* <td nowrap="true">{pet.gender}</td> */}
									<td nowrap="true">{pet.breed}</td>
									{/* <td nowrap="true">{pet.hair}</td> */}
									<td nowrap="true">{pet.color}</td>
									<td nowrap="true">
										{pet.tag_number ? (
											<p className="has-text-success">{pet.tag_number}</p>
										) : (
											<p className="has-text-danger">No tag</p>
										)}
									</td>
									<td nowrap="true">{pet.dob}</td>
									<td nowrap="true">{pet.email}</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td nowrap="true">
								<p>No Pets</p>
							</td>
						</tr>
					)}
				</Table>
			</section>

			{owner ? (
				<OwnerNotes
					owner={owner}
					convertedContent={convertedContent}
					setConvertedContent={setConvertedContent}
				/>
			) : (
				""
			)}

			{owner ? (
				<OwnerExchanges
					owner={owner}
				/>
			) : (
				""
			)}
		</div>
	);
};

export default OwnerSingle;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const OwnerNotes = (props) => {
	const owner = props.owner;
  
	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(owner.notes ? owner.notes : "")
			)
		)
	);

	const handleEditorChange = (state) => {
		setEditorState(state);
		convertContentToHTML();
	};
	const convertContentToHTML = () => {
		let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
		props.setConvertedContent(currentContentAsHTML);
	};

	return (
		<>
			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{owner ? owner.first_name : ""}'s notes
						</p>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12">
						<Editor
							editorClassName="editor-class"
							editorState={editorState}
							onEditorStateChange={handleEditorChange}
						/>
					</div>
				</div>
			</section>
		</>
	);
};

export default OwnerNotes;

OwnerNotes.propTypes = {
	owner: PropTypes.object,
};

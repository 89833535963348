import React from "react";

const Table = (props) => {
	// Takes an array for thTitles for the entire table header

	const selectRow = (e) => {
		console.log(e.target);
		e.target.closest("tr").classList.toggle("is-selected");
	};

	return (
		<div className="table-container">
			<table className="table is-striped is-hoverable is-fullwidth">
				<thead>
					<tr>
						{props.hasCheckbox ? (
							<th width="32px" className="has-text-white">
								<input type="checkbox" onChange={(e) => selectRow(e)}></input>
							</th>
						) : (
							""
						)}
						{props.thTitles
							? props.thTitles.map((th, index) => {
									return (
										<th nowrap="true" key={index} className="has-text-white">{th}</th>
									);
							  })
							: ""}
					</tr>
				</thead>
				<tbody>{props.children}</tbody>
			</table>
		</div>
	);
};

export default Table;

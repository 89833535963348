import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

// import Tag from '../general/tag';
import Input from "../general/input";
// import Select from '../general/select';
import ActionBar from "../general/actionBar";
import PetProfileImage from "./petProfileImage";
import PetMissingBanner from "./petDetailsPage/petMissingBanner.js";
import PetLicensesList from "./petDetailsPage/petLicensesList";
import PetRabiesList from "./petDetailsPage/petRabiesList";
import PetDelete from "./petDetailsPage/petDelete";
import PetOwners from "./petDetailsPage/petOwner";
import PetNotes from "./petDetailsPage/petNotes";

const PetSingle = (props) => {
	const [pet, setPet] = useState();
	const [missing, setMissing] = useState(false);
	const [petProfileImage, setPetProfileImage] = useState();
	const [spayed, setSpayed] = useState(false);
	const [loading, setLoading] = useState(false);
	const [convertedContent, setConvertedContent] = useState(null);
  const [dangerous, setDangerous] = useState()

	useEffect(() => {
		if (props.pet) {
			setPet(props.pet);
		} else {
			getPet();
		}
	}, []);

	const getPet = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "pets/" + props.match.params.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				setPet(res.data.data);
				console.log(res.data.data);
				setPetProfileImage(res.data.data.image);
				setSpayed(res.data.data.spayed_neutered);
        setDangerous(res.data.data.dangerous_pet);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updatePet = () => {
		setLoading(true);

		let petName = document.getElementById("petName");
		let petSpecies = document.getElementById("petSpecies");
		let petBreed = document.getElementById("petBreed");
		let petHair = document.getElementById("petHair");
		let petColor = document.getElementById("petColor");
		let petDob = document.getElementById("petDob");
		let petGender = document.getElementById("petGender");
		const petData = {
			id: pet.id,
			name: petName.value,
			type: petSpecies.value,
			// other_type: "",
			dob: petDob.value,
			breed: petBreed.value,
			color: petColor.value,
			// vet_name: "",
			// vet_number: "",
			image: petProfileImage ? petProfileImage : pet.image,
			hair: petHair.value,
			gender: petGender.value,
			dangerous_pet: dangerous,
			spayed_neutered: spayed ? true : false,
			notes: convertedContent,
			// address1: "",
			// address2: "",
			// apt: "",
			// city: "",
			// zipcode: "",
			// state: "",
			// country_code: "",
			// tag_number: "",
			// description: "",
			// vet_address: "",
			// medical_records: "",
			// crc: "",
			// insurance_name: "",
			// insurance_id: "",
			// microchip_id: "",
			// opt_out_dashboard_img: "",
		};

		axios
			.post(process.env.REACT_APP_API_URL + "pets/edit", petData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}  `,
				},
			})
			.then(function (response) {
				toast.success("Pet updated!");
				setLoading(false);
			})
			.catch(function (error) {
				setLoading(false);
				toast.error(error.data.message);
				console.log(error);
			});
	};

	const deactivateTag = () => {
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"tags/deactivate/" +
					pet.tag_number +
					"/" +
					pet.id,
				{
					tagNumber: pet.tag_number,
					petId: pet.id,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const changeMissing = (e) => {
		if (e.target.value === "Yes") {
			setMissing(true);
		} else setMissing(false);
	};

	return (
		<div>
			<ActionBar
				{...props}
				back={true}
				rightSide={
					<div className="buttons">
						<button
							className="button is-text is-small"
							onClick={() => props.history.goBack()}
						>
							Cancel
						</button>
						<button
							className={
								loading
									? "button is-success is-small is-loading"
									: "button is-success is-small"
							}
							onClick={() => updatePet()}
						>
							Save
						</button>
					</div>
				}
			/>

			{missing ? <PetMissingBanner pet={pet} /> : ""}

			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<p className="has-text-weight-bold is-size-4">
							{pet ? (
								pet.name + "'s information"
							) : (
								<Skeleton height={40} width={400} />
							)}
						</p>
					</div>
				</div>

				<div className="columns">
					<div className="column is-3">
						{petProfileImage || pet ? (
							<PetProfileImage
								petProfileImage={
									petProfileImage ? petProfileImage : pet.profile
								}
								setPetProfileImage={setPetProfileImage}
							/>
						) : (
							<Skeleton height={120} width={"100%"} />
						)}
					</div>
					{pet ? (
						<div className="column is-9">
							<div className="columns is-multiline">
								<div className="column is-4">
									<Input
										label="Name"
										placeholder="Shorty"
										value={pet ? pet.name : ""}
										id="petName"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Species"
										placeholder="Dog"
										value={pet ? pet.type : ""}
										id="petSpecies"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Breed"
										placeholder="Beagle"
										value={pet ? pet.breed : ""}
										id="petBreed"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Hair"
										placeholder="Short"
										value={pet ? pet.hair : ""}
										id="petHair"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Hair color"
										placeholder="Brown and white"
										value={pet ? pet.color : ""}
										id="petColor"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Date of birth"
										placeholder=""
										value={pet ? moment(pet.dob).format("MMM Do YY") : ""}
										id="petDob"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Gender"
										placeholder="Male"
										value={pet ? pet.gender : ""}
										id="petGender"
									/>
								</div>
								<div className="column is-4">
									{/* Replace with select dropdown -> true/false */}
									<div>
										<label className="label">Spayed/Neutered</label>
										<div className="select is-fullwidth">
											<select
												id="petSpayed"
												defaultValue={spayed ? "1" : "0"}
												onChange={(e) => {
													e.target.value === "1"
														? setSpayed(true)
														: setSpayed(false);
												}} //
											>
												<option value="1">Yes</option>
												<option value="0">Unaltered</option>
											</select>
										</div>
									</div>
									{/* <Input
									label="Spayed/Neutered"
									placeholder="Yes"
									value={pet ? pet.spayed_neutered : ""}
									id="petSpayed"
								/> */}
								</div>
								<div className="column is-4">
									<div>
										<label className="label">Missing</label>
										<div className="field">
											<div className="control">
												<div
													className={
														missing
															? "select is-fullwidth is-danger"
															: "select is-fullwidth"
													}
												>
													<select onChange={(e) => changeMissing(e)}>
														<option value="No">No</option>
														<option value="Yes">Yes</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>

                <div className="column is-4">
									<div>
										<label className="label">Dangerous pet</label>
										<div className="field">
											<div className="control">
												<div
													className={
														dangerous
															? "select is-fullwidth is-danger"
															: "select is-fullwidth"
													}
												>
													<select onChange={(e) => setDangerous(e.target.value)} value={dangerous}>
														<option value="0">No</option>
														<option value="1">Yes</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<Skeleton count={8} height={32} width={300} className="mt-4 mr-4" />
					)}
				</div>
			</section>

			<PetLicensesList pet={pet} getPet={() => getPet()} />

			<PetRabiesList pet={pet} getPet={() => getPet()} />

			{props.user && props.user.privileges !== 0 ? <PetOwners pet={pet} /> : ""}

			{pet && props.user && props.user.privileges !== 0 ? (
				<PetNotes
					pet={pet}
					convertedContent={convertedContent}
					setConvertedContent={setConvertedContent}
				/>
			) : (
				""
			)}

			<PetDelete pet={pet} {...props} />

			{/* <section className="section">
				<div className="columns">
					<div className="column is-12">
						<p className="is-size-4 has-text-weight-bold">Pet administration</p>
					</div>
				</div>
				<div className="columns is-multiline">
					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I want to renew this pet's license
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								Both my pet and I relocated
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I don't have this pet anymore
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I want to transfer this pet to another owner
							</div>
						</Link>
					</div>
				</div>
			</section> */}
		</div>
	);
};

export default PetSingle;

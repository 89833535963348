import React, { useState, useEffect } from "react";

const FilterPets = (props) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<div className={isActive ? "dropdown is-active" : "dropdown"}>
			<div className="dropdown-trigger">
				<button
					className="button"
					aria-haspopup="true"
					aria-controls="dropdown-menu3"
					onClick={() => setIsActive(!isActive)}
				>
					<span>Filter</span>
					<span className="icon is-small">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="is-16"
							viewBox="0 0 24 24"
							version="1.1"
						>
							<g id="surface990">
								<path d="M 12 2 C 6.488281 2 2 6.488281 2 12 C 2 17.511719 6.488281 22 12 22 C 17.511719 22 22 17.511719 22 12 C 22 6.488281 17.511719 2 12 2 Z M 12 4 C 16.429688 4 20 7.570312 20 12 C 20 16.429688 16.429688 20 12 20 C 7.570312 20 4 16.429688 4 12 C 4 7.570312 7.570312 4 12 4 Z M 7 8 L 7 10 L 17 10 L 17 8 Z M 8 12 L 8 14 L 16 14 L 16 12 Z M 9 16 L 9 18 L 15 18 L 15 16 Z M 9 16 " />
							</g>
						</svg>
					</span>
				</button>
			</div>
			<div className="dropdown-menu" id="dropdown-menu3" role="menu">
				<div className="dropdown-content">
					<div className="dropdown-item">
						<label className="radio">
							<input
								type="radio"
								name="filter"
								onChange={(e) => {
									setIsActive(!isActive);
									e.target.checked === true
										? props.filterBy("noRabies")
										: props.filterBy("allpets");
								}}
							/>
							<span className="ml-2">No rabies</span>
						</label>
					</div>
					<div className="dropdown-item">
						<label className="radio">
							<input
								type="radio"
								name="filter"
								onChange={(e) => {
									setIsActive(!isActive);
									e.target.checked === true
										? props.filterBy("yesRabies")
										: props.filterBy("allpets");
								}}
							/>
							<span className="ml-2">Has rabies cert</span>
						</label>
					</div>
					<div className="dropdown-item">
						<label className="radio">
							<input
								type="radio"
								name="filter"
								onChange={(e) => {
									setIsActive(!isActive);
									e.target.checked === true
										? props.filterBy("licenseActive")
										: props.filterBy("allpets");
								}}
							/>
							<span className="ml-2">License active</span>
						</label>
					</div>
					<div className="dropdown-item">
						<label className="radio">
							<input
								type="radio"
								name="filter"
								onChange={(e) => {
									setIsActive(!isActive);
									e.target.checked === true
										? props.filterBy("licenseInactive")
										: props.filterBy("allpets");
								}}
							/>
							<span className="ml-2">Expired license</span>
						</label>
					</div>
					<div className="dropdown-item">
						<label className="radio">
							<input
								type="radio"
								name="filter"
								onChange={(e) => {
									setIsActive(!isActive);
									e.target.checked === true
										? props.filterBy("noLicense")
										: props.filterBy("allpets");
								}}
							/>
							<span className="ml-2">No license</span>
						</label>
					</div>

          <div className="dropdown-item">
						<label className="radio">
							<input
								type="radio"
								name="filter"
								onChange={(e) => {
									setIsActive(!isActive);
									e.target.checked === true
										? props.filterBy("dangerous")
										: props.filterBy("allpets");
								}}
							/>
							<span className="ml-2">Dangerous pets</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FilterPets;

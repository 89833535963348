import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Table from "../../general/table";

const PetOwners = (props) => {
	if (props.pet) {
		// To clarify confusion about owner (singular) = props.owners (plural): we display only one owner for now, even if the object is name 'owners'
		const owner = props.pet.owners;

		return (
			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{props.pet.name}'s owner
						</p>
					</div>
				</div>
				<Table
					thTitles={[
						"Name",
						"Email",
						"Address",
						"City",
						"Phone",
					]}
				>
					<tr>
						<td nowrap="true">
							<Link to={"/admin/owner/" + owner.id} className="is-flex is-align-items-center">
								<figure className="image is-24x24">
									<img
										className="is-rounded"
										src={owner.picture}
									/>
								</figure>
                <span className="ml-1">{owner.first_name} {owner.last_name}</span>
							</Link>
						</td>
						<td nowrap="true">{owner.email}</td>
						<td nowrap="true">{owner.address1}</td>
						<td nowrap="true">{owner.city}</td>
						<td nowrap="true">{owner.phone}</td>
					</tr>
				</Table>
			</section>
		);
	} else {
		return (
			<section className="section">
				<Skeleton height={40} width={400} />
			</section>
		);
	}
};

export default PetOwners;

PetOwners.propTypes = {
	owners: PropTypes.object,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "../general/table";
import moment from "moment";
import axios from "axios";

const OwnerExchanges = (props) => {
	const [events, setEvents] = useState([]);
	const owner = props.owner;

	useEffect(() => {
		// axios
		// 	.get(process.env.REACT_APP_API_URL + "exchanges/" + owner.id, {
		// 		headers: {
		// 			Accept: "application/json",
		// 			Authorization: "Bearer " + localStorage.getItem("token"),
		// 			"Content-Type": "application/x-www-form-urlencoded",
		// 		},
		// 	})
		// 	.then((res) => {
		// 		console.log(res.data.data);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});


      setEvents([
        { title: "Account created", date: owner.created_at },
        owner.updated_at !== owner.created_at
          ? { title: "Account updated", date: owner.updated_at }
          : "",
        // Pet created at
        owner && owner.pets && owner.pets.length > 0 && owner.pets[0]
          ? { title: "Pet added", date: owner.pets[0].created_at }
          : "",
        // Rabies cert added
        owner &&
        owner.pets &&
        owner.pets.length > 0 &&
        owner.pets[0] &&
        owner.pets[0].rabiescert &&
        owner.pets[0].rabiescert.length > 0 &&
        owner.pets[0].rabiescert[0]
          ? {
              title: "Rabies cert added",
              date: owner.pets[0].rabiescert[
                owner.pets[0].rabiescert.length - 1
              ].created_at,
            }
          : "",

        // License issued
        owner &&
        owner.pets &&
        owner.pets.length > 0 &&
        owner.pets[0] &&
        owner.pets[0].license &&
        owner.pets[0].license.length > 0 &&
        owner.pets[0].license[0]
          ? {
              title: "License issued",
              date: owner.pets[0].rabiescert[
                owner.pets[0].rabiescert.length - 1
              ].created_at,
            }
          : "",
      ]);
	}, []);

	// console.log(events);

	return (
		<>
			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{owner ? owner.first_name : ""}'s activity history
						</p>
					</div>
				</div>

				<div className="card">
					<Table thTitles={["Event", "Time", "Status"]}>
						{events.length > 0
							? events
									.sort(function (a, b) {
										// Turn your strings into dates, and then subtract them
										// to get a value that is either negative, positive, or zero.
										return new Date(b.date) - new Date(a.date);
									})
									.map((singleEvent, index) => {
										if (singleEvent.date) {
											return (
												<tr>
													<td>{singleEvent.title}</td>
													<td>
														{moment(singleEvent.date).format("ll, hh:mm A")}
													</td>
													<td>
														<span className="tag is-success">Success</span>
													</td>
												</tr>
											);
										}
									})
							: ""}
					</Table>
				</div>
			</section>
		</>
	);
};

export default OwnerExchanges;

OwnerExchanges.propTypes = {
	owner: PropTypes.object,
};

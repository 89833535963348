import React from "react";
import { Link } from "react-router-dom";

import Tag from "../general/tag";
import moment from "moment";
import NoProfile from "../../assets/noprofile.svg";

const PetPreview = (props) => {
	const pet = props.pet;

	const petImage = pet.image
		? pet.image.includes("cloudinary")
			? pet.image
			: "https://api.roamingtails.co/uploads/profiles/" + pet.image
		: NoProfile;

	return (
		<Link to={"/user/pet/" + pet.id} className="card is-horizontal is-flex">
			<div
				className="card-image"
				style={{
					backgroundImage: "url(" + petImage + ")",
					minHeight: "200px",
				}}
			></div>
			<div className="card-content">
				<p>
					<span className="has-text-weight-bold is-size-4">{pet.name}</span> #
					{pet.tag_number}
				</p>
				<div className="field">
					<p>
						{pet.breed}, {moment().from(pet.dob, true)} old
					</p>
				</div>
				<div className="field is-grouped is-grouped-multiline">
					{pet && pet.rabiescert ? (
						// Check if rabies cert is expired or not (if current day > expiration_date)
						moment().isAfter(pet.rabiescert.expiration_date) ? (
							<Tag type="danger">
								<p>Rabies certificate expired</p>
							</Tag>
						) : // Check if rabies cert is going to expire soon (current day > (expiration_date - 3 months))
						moment().isAfter(
								moment(pet.rabiescert.expiration_date).subtract(3, "months")
						  ) ? (
							<Tag type="warning">
								<p>Rabies certificate expiring soon</p>
							</Tag>
						) : (
							<Tag type="success">
								<p>Rabies certificate active</p>
							</Tag>
						)
					) : (
						""
					)}

					{pet && pet.tag_number && pet.rabiescert ? (
						// Check if license/tag is expired or not (if current day > expiration_date)
						moment().isAfter(moment(pet.rabiescert.cert_date).add(pet.rabiescert.cert_time, "y")) ? (
							<Tag type="danger">
								<p>License expired</p>
							</Tag>
						) : // Check if license/tag is going to expire soon (current day > (expiration_date - 3 months))
						moment().isAfter(
								moment(moment(pet.rabiescert.cert_date).add(pet.rabiescert.cert_time, "y")).subtract(3, "months")
						  ) ? (
							<Tag type="warning">
								<p>License expiring soon</p>
							</Tag>
						) : (
							<Tag type="success">
								<p>License active</p>
							</Tag>
						)
					) : (
						""
					)}
				</div>
			</div>
		</Link>
	);
};

export default PetPreview;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { CSVLink } from "react-csv";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

import Table from "../general/table";
import ActionBar from "../general/actionBar";
import TitleBanner from "../general/titleBanner";

import SearchIcon from "../../assets/icons8-search.svg";
import CatIllustration from "../../assets/cat-illustration.svg";
import DogIllustration from "../../assets/dog-illustration.svg";
import FilterPets from "./filterPets";

const PetsInternal = (props) => {
	const [pets, setPets] = useState();
	const [filteredPets, setFilteredPets] = useState();
	const [entriesNo, setEntriesNo] = useState(50);
	const [filterStr, setFilterStr] = useState("");
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: moment(new Date()).add(7, "days"),
			key: "selection",
		},
	]);
	const [isActive, setIsActive] = useState(false);
	const [csvData, setCsvData] = useState([
		[
			"Name",
			"License",
			"License Date",
			"License Expiry Date",
			"Type",
			"Breed",
			"Color",
			"Tag #",
			"Date of birth",
			"Email",
			"Payment method",
			"Owner",
			"Vet name",
			"Vet address",
			"Vet number",
		],
	]);

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "pets", {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setPets(res.data.data);
				setFilteredPets(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const selectRow = (e) => {
		console.log(e.target);
		e.target.closest("tr").classList.toggle("is-selected");
	};

	const downloadCsv = () => {
		console.log(filteredPets);
		filteredPets
			.filter((pet) => {
				if (
					JSON.stringify(pet).toLowerCase().includes(filterStr.toLowerCase())
				) {
					return pet;
				}
			})
			.forEach((pet) => {
				setCsvData((oldArray) => [
					...oldArray,
					[
						pet.name,
						pet.tag_number ? pet.tag_number : "N/A",
						pet.license.length > 0 ? pet.license[0].license_date : "N/A",
						pet.license.length > 0
							? pet.license[0].license_expiration_date
							: "N/A",
						pet.type,
						pet.breed,
						pet.color,
						pet.tag_number,
						pet.dob,
						pet.email,
						"N/A",
						pet.owners && pet.owners.first_name && pet.owners.last_name
							? pet.owners.first_name + " " + pet.owners.last_name
							: "",
						pet.vet_name ? pet.vet_name : "",
						pet.vet_address ? pet.vet_address : "",
						pet.vet_number ? pet.vet_number : "",
					],
				]);
			});
	};

	const filterByString = (e) => {
		setFilterStr(e.target.value);
	};

	const filterBy = (criteria) => {
		// console.log(criteria);
		if (pets) {
			const petsFiltered = pets
				.filter((pet) => {
					if (
						JSON.stringify(pet).toLowerCase().includes(filterStr.toLowerCase())
					) {
						return pet;
					}
				})
				.filter((pet) => {
					switch (criteria) {
						case "noRabies":
							return (
								(pet.rabiescert && pet.rabiescert.length < 1) ||
								(pet.rabiescert.length > 0 &&
									!pet.rabiescert[pet.rabiescert.length - 1].certificate)
							);
						case "yesRabies":
							return (
								pet.rabiescert &&
								pet.rabiescert.length > 0 &&
								pet.rabiescert[pet.rabiescert.length - 1] &&
								pet.rabiescert[pet.rabiescert.length - 1].certificate &&
								pet.rabiescert[pet.rabiescert.length - 1].certificate !== ""
							);
						case "licenseActive":
							return (
								pet.license.length > 0 &&
								moment(
									pet.license[pet.license.length - 1].license_expiration_date
								).isAfter(moment())
							);
						case "licenseInactive":
							return (
								pet.license.length > 0 &&
								moment(
									pet.license[pet.license.length - 1].license_expiration_date
								).isBefore(moment())
							);
						case "noLicense":
							return pet.license.length < 1 || !pet.license;
            case "dangerous":
							return pet.dangerous_pet == 1;
						case "allpets":
							return pets;
						default:
							return pets;
					}
				});
			setFilteredPets(petsFiltered);
			console.log(petsFiltered);
		}
	};

	const filterByDate = (startDate, endDate) => {
		if (pets) {
			const petsFiltered = pets
				.filter((pet) => {
					if (
						JSON.stringify(pet).toLowerCase().includes(filterStr.toLowerCase())
					) {
						return pet;
					}
				})
				.filter((pet) => {
					return (
						moment(pet.created_at).isBetween(startDate, endDate) ||
						moment(pet.created_at).isSame(startDate) ||
						moment(pet.created_at).isSame(endDate)
					);
				});
			setFilteredPets(petsFiltered);
			console.log(petsFiltered);
		}
	};

	return (
		<div>
			<ActionBar
				leftSide={
					<div className="field has-addons">
						<div className="control">
							<button className="button is-static">
								<img
									src={SearchIcon}
									height="16"
									className="is-icon is-16"
									alt="Search..."
								></img>
							</button>
						</div>
						<div className="control is-expanded">
							<input
								className="input"
								type="text"
								placeholder="Pet name or tag number"
								onChange={(e) => filterByString(e)}
							/>
						</div>
					</div>
				}
			/>
			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<TitleBanner
							leftImg={CatIllustration}
							rightImg={DogIllustration}
							title="Pets"
							subtitle={
								(filteredPets ? filteredPets.length : "0") + " pets found"
							}
						/>
					</div>
				</div>
			</section>
			<section className="section py-0">
				<div className="columns">
					<div className="column is-12">
						<div className="buttons is-justify-content-space-between">
							<div className="buttons">
								<FilterPets filterBy={(criteria) => filterBy(criteria)} />
								<div
									className={
										isActive ? "dropdown is-active mx-3" : "dropdown mx-3"
									}
								>
									<div className="dropdown-trigger">
										<button
											className="button"
											aria-haspopup="true"
											aria-controls="dropdown-menu3"
											onClick={(e) => {
												e.stopPropagation();
												setIsActive(!isActive);
											}}
										>
											<span>Filter by date</span>
											<span className="icon is-small">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="is-16"
													viewBox="0 0 24 24"
													version="1.1"
												>
													<g id="surface5664">
														<path d="M 6 1 L 6 3 L 5 3 C 3.898438 3 3 3.898438 3 5 L 3 19 C 3 20.101562 3.898438 21 5 21 L 19 21 C 20.101562 21 21 20.101562 21 19 L 21 5 C 21 3.898438 20.101562 3 19 3 L 18 3 L 18 1 L 16 1 L 16 3 L 8 3 L 8 1 Z M 5 5 L 19 5 L 19 7 L 5 7 Z M 5 9 L 19 9 L 19 19 L 5 19 Z M 5 9 " />
													</g>
												</svg>
											</span>
										</button>
									</div>
									<div
										className="dropdown-menu"
										id="dropdown-menu3"
										role="menu"
									>
										<div className="dropdown-content">
											<DateRangePicker
												onChange={(item) => {
													setDateRange([item.selection]);
													filterByDate(
														item.selection.startDate,
														item.selection.endDate
													);
													console.log(item.selection);
													// console.log(dateRange);
												}}
												showSelectionPreview={true}
												moveRangeOnFirstSelection={false}
												months={2}
												ranges={dateRange}
												direction="horizontal"
											/>
										</div>
									</div>
								</div>

								<button
									className="button is-ghost"
									onClick={() => {
										setIsActive(false);
										filterBy("allpets");
										setDateRange([
											{
												startDate: new Date(),
												endDate: moment(new Date()).add(7, "days"),
												key: "selection",
											},
										]);
									}}
								>
									Clear filters
								</button>
							</div>

							<CSVLink
								onClick={() => downloadCsv()}
								className="button is-primary"
								data={csvData}
							>
								Export to .csv
							</CSVLink>
						</div>
					</div>
				</div>
			</section>
			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<Table
							hasCheckbox={true}
							thTitles={[
								"Name",
								"License",
								"License date",
								"License expiry date",
								"Type",
								"Breed",
								"Color",
								"Dangerous pet",
								"Tag #",
								"Date of birth",
								"Email",
								"Payment Method",
								"Owner",
								"Rabies cert",
								"Vet name",
								"Vet address",
								"Vet number",
							]}
						>
							{filteredPets ? (
								filteredPets
									.filter((pet) => {
										if (
											JSON.stringify(pet)
												.toLowerCase()
												.includes(filterStr.toLowerCase())
										) {
											return pet;
										}
									})
									.slice(0, entriesNo)
									.map((pet, index) => {
										return (
											<tr key={index}>
												<td nowrap="true">
													<input
														type="checkbox"
														onChange={(e) => selectRow(e)}
													></input>
												</td>
												<td nowrap="true">
													<Link to={"/admin/pet/" + pet.id}>{pet.name}</Link>
												</td>
												{/* <td nowrap="true">
													{pet.license && pet.license.length > 0 && moment(pet.license[0].license_expiration_date).isBefore(moment())
														? <p className="has-text-success">Active</p>
														: <p className="has-text-danger">Inactive</p>}
												</td> */}
												<td nowrap="true">
													{pet.license &&
													pet.license.length > 0 &&
													moment(
														pet.license[pet.license.length - 1]
															.license_expiration_date
													).isAfter(moment()) ? (
														<p className="has-text-success">Active</p>
													) : (
														<p className="has-text-danger">Inactive</p>
													)}
												</td>
												<td nowrap="true">
													{pet.license && pet.license.length > 0
														? moment(pet.license[0].license_date).format(
																"MMM Do YYYY"
														  )
														: "N/A"}
												</td>
												<td nowrap="true">
													{pet.license && pet.license.length > 0
														? moment(
																pet.license[0].license_expiration_date
														  ).format("MMM Do YYYY")
														: "N/A"}
												</td>
												<td nowrap="true">{pet.type}</td>
												{/* <td nowrap="true">{pet.gender}</td> */}
												<td nowrap="true">{pet.breed}</td>
												{/* <td nowrap="true">{pet.hair}</td> */}
												<td nowrap="true">{pet.color}</td>
												<td nowrap="true">
													{pet.dangerous_pet ? (
														<span className="tag is-danger">Dangerous</span>
													) : (
														<span className="tag is-light has-opacity-65">Not dangerous</span>
													)}
												</td>
												<td nowrap="true">
													{pet.tag_number ? (
														<p className="has-text-success">{pet.tag_number}</p>
													) : (
														<p className="has-text-danger">No tag</p>
													)}
												</td>
												<td nowrap="true">
													{moment(pet.dob).format("MMM Do YYYY")}
												</td>
												<td nowrap="true">{pet.email}</td>
												<td nowrap="true">N/A</td>
												<td nowrap="true">
													<Link to={"/admin/owner/" + pet.owner_id}>
														{pet.owners
															? pet.owners.first_name +
															  " " +
															  pet.owners.last_name
															: ""}
													</Link>
												</td>
												<td nowrap="true">
													{pet &&
													pet.rabiescert[pet.rabiescert.length - 1] &&
													pet.rabiescert[pet.rabiescert.length - 1]
														.certificate ? (
														<a
															href={
																pet.rabiescert[pet.rabiescert.length - 1]
																	.certificate
															}
															target="_blank"
														>
															Open certificate
														</a>
													) : (
														"N/A"
													)}
												</td>
												<td nowrap="true">{pet.vet_name}</td>
												<td nowrap="true">{pet.vet_address}</td>
												<td nowrap="true">{pet.vet_number}</td>
											</tr>
										);
									})
							) : (
								<tr>
									<td nowrap="true"></td>
									<td nowrap="true">
										<Skeleton height={24} width={"100%"} />
									</td>
									<td nowrap="true">
										<Skeleton height={24} width={"100%"} />
									</td>
									<td nowrap="true">
										<Skeleton height={24} width={"100%"} />
									</td>
									<td nowrap="true">
										<Skeleton height={24} width={"100%"} />
									</td>
								</tr>
							)}
						</Table>
						<button
							className="button is-primary"
							onClick={() => setEntriesNo(entriesNo + 50)}
						>
							Load more...
						</button>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PetsInternal;

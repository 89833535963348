import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "./AuthContext";
import Login from "../account/login";

import Tag from "../general/tag";

const AdminRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<AuthConsumer>
		{({ isAuth, user }) => (
			<Route
				{...rest}
				render={(props) => (
					<Layout {...props}>
						{isAuth && user ? (
							user.privileges >= 1 ? (
								<Component {...props} />
							) : (
								<section className="section">
									<Tag type="danger">
										<p>
											This page is restricted, please login with an admin
											account.
										</p>
									</Tag>
								</section>
							)
						) : (
							<Login isAdmin={true} />
						)}
					</Layout>
				)}
			/>
		)}
	</AuthConsumer>
);

export default AdminRoute;

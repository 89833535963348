import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
	const [isAuth, setIsAuth] = useState(false);
	const [user, setUser] = useState();
	const [domain, setDomain] = useState();
	const [errorMessage, setErrorMessage] = useState(null);
	const history = useHistory();

	useEffect(() => {
		const localUser = localStorage.getItem("user");
		if (localUser) {
			setIsAuth(true);
			setUser(JSON.parse(localUser));
		}
	}, []);

	useEffect(() => {
		const domain = window.location.hostname.split(".")[0];
		console.log(domain);

		if (domain !== "localhost") {
			axios
				.get(process.env.REACT_APP_API_URL + "configurations/" + domain, {
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				.then((res) => {
					setDomain(res.data.data);
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			axios
				.get(process.env.REACT_APP_API_URL + "configurations/testcounty", {
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				.then((res) => {
					setDomain(res.data.data);
					console.log(res.data.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, []);

	const login = (e, isAdmin) => {
		e.preventDefault();
		const creds =
			"email=" +
			document.getElementById("login-email").value +
			"&password=" +
			document.getElementById("login-password").value;

		axios
			.post(process.env.REACT_APP_API_URL + "authenticate?" + creds)
			.then(function (res) {
				if (res.status) {
					if (res.data.token) {
						console.log("User profile", res);
						console.log("User token", res.data.token);
						localStorage.setItem("token", res.data.token);
						localStorage.setItem("user", JSON.stringify(res.data.user));
						setUser(res.data.user);
						setIsAuth(true);
						setErrorMessage();
						if (isAdmin) {
							history.push("/admin/");
						} else {
							history.push("/");
						}
					} else {
						console.log(res);
						setErrorMessage(res.data.message);
					}
				} else {
					console.log(res);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const logout = (e) => {
		e.preventDefault();
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		setIsAuth(false);
		setUser(null);
		history.push("/");
	};

	return (
		<AuthContext.Provider
			value={{
				isAuth: isAuth,
				login: login,
				logout: logout,
				user: user,
				domain: domain,
				errorMessage: errorMessage,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };

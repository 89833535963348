import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import PetProfileImage from "../pets/petProfileImage";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import Tag from "../general/tag";

const ScanResultQR = (props) => {
	const [pet, setPet] = useState();
	const [settings, setSettings] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		getLocation();
	}, []);

	const getPet = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "pet/tag/" + props.match.params.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data);
				if (!res.data.success) {
					toast.error(res.data.message);
				} else {
					setPet(res.data.data);
					getSettings(res.data.data.owner_id);
					// props.history.push('/user/scan/'+res.data.data.id)
				}
			})
			.catch((err) => {
				console.log(err);
				// toast.error(err.data.message);
			});
	};

	const scanTag = (lat, lng) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + "tag/scanner",
				{
					tag_number: props.match.params.id,
					latitude: lat,
					longitude: lng,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				if (!res.data.success) {
					toast.error(res.data.message);
				} else {
					getPet(res.data.data.tag_number);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getLocation = () => {
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0,
		};
		function success(pos) {
			var crd = pos.coords;
			console.log("Your current position is:");
			console.log(`Latitude : ${crd.latitude}`);
			console.log(`Longitude: ${crd.longitude}`);
			console.log(`More or less ${crd.accuracy} meters.`);
			scanTag(crd.latitude, crd.longitude);
			setError("");
		}
		function error(err) {
			setError(
				"You don't have location services activated, so the owner doesn't get alerted with the location of the scan. Please contact the owner of the pet yourself!"
			);
			getPet();
			console.warn(`ERROR(${err.code}): ${err.message}`);
		}
		navigator.geolocation.getCurrentPosition(success, error, options);
	};

	const getSettings = (ownerId) => {
		axios
			.get(process.env.REACT_APP_API_URL + "privacy/" + ownerId, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setSettings(res.data.data[0]);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<section className="section">
				{pet && settings ? (
					<div>
						<div className="columns">
							<div className="column is-12">
								<p className="has-text-weight-bold is-size-4">
									{pet ? pet.name : ""}'s information
								</p>
							</div>
						</div>

						<div className="columns is-multiline">
							<div className="column is-3">
								{pet ? (
									settings && settings.image ? (
										<PetProfileImage
											petProfileImage={pet.picture}
											noChange={true}
										/>
									) : (
										"Image Hidden"
									)
								) : (
									<Skeleton height={120} width={"100%"} />
								)}
							</div>

							<div className="column is-9">
								<div className="columns is-multiline">
									<div className="column is-12">
										<p>Owner information</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">First name</p>
										<p className="has-text-weight-bold">
											{pet && pet.first_name ? (
												settings && settings.first_name ? (
													pet.first_name
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Last name</p>
										<p className="has-text-weight-bold">
											{pet && pet.last_name ? (
												settings && settings.last_name ? (
													pet.last_name
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Address 1</p>
										<p className="has-text-weight-bold">
											{pet && pet.address1 ? (
												settings && settings.address ? (
													pet.address1
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Address 2</p>
										<p className="has-text-weight-bold">
											{pet && pet.address2 ? (
												settings && settings.address ? (
													pet.address2
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Apt</p>
										<p className="has-text-weight-bold">
											{pet && pet.apt ? (
												settings && settings.address ? (
													pet.apt
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Owner email</p>
										<p className="has-text-weight-bold">
											{pet && pet.email ? (
												settings && settings.email ? (
													pet.email
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Phone</p>
										<p className="has-text-weight-bold">
											{pet && pet.phone ? (
												settings && settings.phone ? (
													pet.phone
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>

									<div className="column is-6">
										<p className="is-size-7">Mobile</p>
										<p className="has-text-weight-bold">
											{pet && pet.mobile ? (
												settings && settings.mobile ? (
													pet.mobile
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
								</div>
							</div>
							<div className="column is-12">
								<hr className="separator" />
							</div>

							<div className="column is-3">
								{pet ? (
									<PetProfileImage
										petProfileImage={pet.image}
										noChange={true}
									/>
								) : (
									<Skeleton height={120} width={"100%"} />
								)}
							</div>

							<div className="column is-9">
								<div className="columns is-multiline">
									<div className="column is-12">
										<p>Pet information</p>
									</div>

									<div className="column is-6">
										<p className="is-size-7">Name</p>
										<p className="has-text-weight-bold">
											{pet && pet.name ? (
												settings && settings.pet_name ? (
													pet.name
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Breed</p>
										<p className="has-text-weight-bold">
											{pet && pet.breed ? (
												settings && settings.breed ? (
													pet.breed
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									{/* <div className="column is-6">
										<p className="is-size-7">Hair</p>
										<p className="has-text-weight-bold">
											{pet && pet.hair ? pet.hair : "N/A"}
										</p>
									</div> */}
									<div className="column is-6">
										<p className="is-size-7">Hair color</p>
										<p className="has-text-weight-bold">
											{pet && pet.color ? (
												settings && settings.color ? (
													pet.color
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									<div className="column is-6">
										<p className="is-size-7">Date of birth</p>
										<p className="has-text-weight-bold">
											{pet && pet.dob ? (
												settings && settings.dob ? (
													moment(pet.dob).format("MMM Do YY")
												) : (
													<span className="has-opacity-45">Hidden</span>
												)
											) : (
												"N/A"
											)}
										</p>
									</div>
									{/* <div className="column is-6">
								<p className="is-size-7">Gender</p>
								<p className="has-text-weight-bold">
									{pet && pet.gender ? pet.gender : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Spayed/Neutered</p>
								<p className="has-text-weight-bold">
									{pet && pet.spayed ? pet.spayed : "N/A"}
								</p>
							</div> */}
								</div>
							</div>
						</div>
					</div>
				) : (
					<Skeleton count={8} height={32} width={300} className="mt-4 mr-4" />
				)}
			</section>
		</div>
	);
};

export default ScanResultQR;
